class OpenMic {

    constructor(dateTime, venueName, address, totalSpots, attendees, waitlist, reserveTimes, host, hostName, id, type, requiresPhysicalSignup, timeLimit, performer, performerStart, rules, live, ended, price) {
      this.dateTime = dateTime;
      this.venueName = venueName;
      this.address = address;
      this.totalSpots = totalSpots;
      this.attendees = attendees;
      this.waitlist = waitlist;
      this.reserveTimes = reserveTimes;
      this.host = host;
      this.hostName = hostName;
      this.id = id;
      this.type = type;
      this.requiresPhysicalSignup = requiresPhysicalSignup;
      this.timeLimit = timeLimit;
      this.performer = performer;
      this.performerStart = performerStart;
      this.rules = rules;
      this.live = live;
      this.ended = ended;
      this.price = price;
    }
  
  }

export default OpenMic;