import React from "react";
import { Button } from "@chakra-ui/react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

function PaymentView({ setMessage, message, event, reset, finishRegister, isLoading, setIsLoading }) {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async(e) => {
        if (!stripe || !elements) {
            console.log(`NO STRIPE ${stripe} NO ELEMENTS ${elements}`);
            return;
        }

        setIsLoading(true);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            elements: elements
        });

        if (error) {
            console.error(error);
            const errorMessage = error.message ? error.message : 'Unexpected Error';
            if (error.code === 'payment_intent_unexpected_state' || error.type === 'invalid_request_error') {
                setIsLoading(true);
                reset(event);

            }
            setMessage(errorMessage);
        } else if (paymentMethod.id) {
            console.log('Confirmed Payment!');
            console.log(`Registering for ${event.venueName}`);
            finishRegister(event, paymentMethod.id);
            return;
        } else {
            console.log('Payment error', error, JSON.stringify(paymentMethod));
        }
    
        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <div onClick={() => {
            if (isLoading) return;
            reset();
        }}
            style={{ zIndex: 998, position: 'absolute', top: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.88)' }}>
            <form id="payment-form" className="payment-form" onSubmit={handleSubmit}>
                <PaymentElement 
                    id="payment-element" 
                    options={paymentElementOptions} 
                    onChange={() => {
                        setMessage('');
                    }}
                    disabled={isLoading || !stripe || !elements}
                />
                <Button onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    elements.submit()
                    .then(() => {
                        handleSubmit(e);
                    })
                    .catch((error) => {
                        console.error(error);
                        setMessage(error.message);
                        setIsLoading(false);
                    });

                    return false;
                }} style={{ padding: '8px 12px', width: '200px', height: '48px', fontSize: '20px', borderRadius: '8px', marginBottom: '12px', marginTop: '16px', backgroundColor: '#eb721b', fontWeight: 800, color: 'white' }} isLoading={isLoading} disabled={isLoading || !stripe || !elements} id="submit">
                    Pay now
                </Button>
                {message && <div style={{ color: 'red', fontWeight: 700 }} id="payment-message">{message}</div>}
            </form>
        </div>
    )
}

export default PaymentView;