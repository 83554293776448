import React, { useEffect, useState, useCallback, useRef } from 'react';
import EventCell from './EventCell';
import 'font-awesome/css/font-awesome.min.css';

const DefaultView = ({ paymentEvent, account, events, query, setQuery, savedScrollPosition, setSavedScrollPosition, scrollBelowEnded, setScrollBelowEnded, reserveAction, reserveLoading }) => {

  let _endedHeader = false;

  const defaultViewRef = useRef(null);
  const endedHeaderRef = useRef(null);
  const inputField = useRef(null);
  const clearButton = useRef(null);
  const contentRef = useRef(null);
  const fixedHeader = useRef(null);

  const scrollTimeout = useRef(null);

  const handleScroll = useCallback((deltaY=0) => {
    if (paymentEvent) return;
    if (defaultViewRef.current && endedHeaderRef.current) {
      const scrollPosition = defaultViewRef.current.scrollTop+deltaY;
      const endedHeaderPosition = endedHeaderRef.current.offsetTop;

      defaultViewRef.current.scrollTop = scrollPosition;

      setScrollBelowEnded(scrollPosition >= (endedHeaderPosition - 144));

      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        setSavedScrollPosition(scrollPosition);
      }, 200);
      
    }
  },[setScrollBelowEnded,setSavedScrollPosition, paymentEvent]);

  const handleMouseWheel = useCallback((event) => {
    handleScroll(event.deltaY);
  },[handleScroll]);

  useEffect(() => {
    window.addEventListener('wheel', handleMouseWheel);
    return () => {
      window.removeEventListener('wheel', handleMouseWheel);
    };
  }, [handleMouseWheel]);

  const filterEvents = useCallback((typed) => {
    if (typed) {
      setSavedScrollPosition(0);
    } else {
      if (inputField.current) inputField.current.value = query;
      handleScroll();
    }
    const _query =inputField.current ? inputField.current.value.toLowerCase() : '';
    setQuery(_query);
    
    if (clearButton.current) clearButton.current.style.display = query === '' ? 'none' : 'block';

  },[query, setSavedScrollPosition, handleScroll, setQuery]);

  useEffect(() => {
    if (defaultViewRef.current) defaultViewRef.current.scrollTop = savedScrollPosition;
  },[savedScrollPosition]);

  useEffect(() => {
    filterEvents(false);
  },[events, filterEvents]);

  const [searching, setSearching] = useState(false);

  return (
    <div className="content" id="content" ref={contentRef}>
      <div className="fixed-header" ref={fixedHeader}>
        <div className="search-container"
        style={{ border: searching || query !== '' ? '2.5px solid #eb721b' : '2.5px solid #ddd' }}
        >
          <input autoCorrect='off' autoComplete='off' onFocus={() => setSearching(true)} onBlur={() => setSearching(false)} onInput={filterEvents} ref={inputField} id='search' type="text" placeholder="Search" className="search-field" />
          <span onClick={() => {
            if (inputField.current) {
              inputField.current.value = '';
              inputField.current.focus();
            }
            filterEvents(true);
          }} 
          ref={clearButton}
          style={{ display: query === '' ? 'none' : 'block'}}
          className="clear-button" id="clearButton">&#10006;</span>
        </div>
        <div className="events-header">
          <h2 style={{ cursor: 'default', color: 'white', fontSize: '39px', fontWeight: '800', margin: '24px 0 8px 3.6vw' }}>
            {events.length === 0 ? '' : (scrollBelowEnded || !(query === '' ? events : events.filter(mic => mic.venueName.toLowerCase().includes(query) || mic.hostName.toLowerCase().includes(query) || mic.address.toLowerCase().includes(query))).find(x => !x.ended) ? 'Ended' : 'Events')}
          </h2>
        </div>
      </div>
      <div className="default-view" id="defaultView" ref={defaultViewRef}>
        <div id='list' className="event-list">
          {(query === '' ? events : events.filter(mic => mic.venueName.toLowerCase().includes(query) || mic.hostName.toLowerCase().includes(query) || mic.address.toLowerCase().includes(query))).map((event) => {
            if (event.ended && !_endedHeader) {
              _endedHeader = true;
              return (
                <div ref={endedHeaderRef} key="endedHeader" id="endedHeader" style={{ width: '80vw', display: 'flex', marginTop: '28px', marginBottom: '11px', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ borderRadius: '6px', height: '3px', width: '60vw', backgroundColor: '#999', opacity: '80%', marginBottom: '36px'}}></div>
                  <EventCell key={event.id} event={event} reserveAction={reserveAction} account={account} reserveLoading={reserveLoading} />
                </div>
              );
            }
            return <EventCell key={event.id} event={event} reserveAction={reserveAction} account={account} reserveLoading={reserveLoading} />
        })}
        </div>
      </div>
    </div>
  );
};

export default DefaultView;