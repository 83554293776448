class SlottedUser {

    constructor(data, uid) {
      this.bio = data.bio;
      this.customerID = data.customerID;
      this.hasPicture = data.hasPicture;
      this.instagram = data.instagram;
      this.isHost = data.isHost;
      this.openMics = data.openMics;
      this.pushToken = data.pushToken;
      this.twitter = data.twitter;
      this.username = data.username;
      this.uid = uid;
    }
  
  }

export default SlottedUser;