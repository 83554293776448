const Background = () => {
  return (
    // <div className="background-container">
    //   <div className="html-embed-8 w-embed">
    //     <div className="element"></div>
    //   </div>
    // </div>
    <>
      <video id="myVideo" autoPlay loop muted playsInline className="video" controls={false}>
        <source src="/background3.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default Background;