import React, { useEffect, useRef } from 'react';
import { auth } from './firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';

function ProfileView({ show, onClose, account, _setAccount }) {

    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: (res) => { return false },
        },
    };

    const unsub = useRef(null);

    useEffect(() => {

        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            if (unsub.current) unsub.current();
            unsub.current = _setAccount(user);
        });

        return () => unregisterAuthObserver();

    },[_setAccount]);

    return (
        <div className='profile-view show' onClick={onClose} style={{ display: show ? 'flex' : 'none'}}>
            <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                {!account ? 
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                    :
                    ''
                }
            </div>
        </div>
    );
}

export default ProfileView;