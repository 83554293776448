import React, { useEffect, useState, useRef, useCallback } from 'react';
import { storage } from './firebase';
import { ref, getDownloadURL } from "firebase/storage";
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

const EventCell = ({ event, reserveAction, account, reserveLoading }) => {

    const [attendeeImages, setAttendeeImages] = useState([]);

    const eventCell = useRef(null);

    const cellHoverOn = useCallback(() => {
        eventCell.current.style.border = event.live ? '3px solid green' : event.ended ? '3px solid #ccc' : '3px solid #eb721b';
    },[event]);

    const cellHoverOff = useCallback(() => {
        eventCell.current.style.border = event.live ? '3px solid green' : '3px solid #ccc';
    },[event]);

    useEffect(() => {
        const fetchAttendeeImages = async () => {
            const images = [];

            for (let i = 0; i < Math.min(10, event.attendees.length); i++) {
                let imgSrc = window.localStorage.getItem(event.attendees[i]);

                let img = new Image()
                
                if (!imgSrc) {
                    try {
                        const imageRef = ref(storage, `/profileImgs/${event.attendees[i]}.png`);
                        imgSrc = await getDownloadURL(imageRef);
                    } catch (error) {
                        imgSrc = 'me_active.png';
                    }
                    window.localStorage.setItem(event.attendees[i], imgSrc);
                }

                img.src = imgSrc;
                
                images.push(img);
            }

            setAttendeeImages(images);
        };

        fetchAttendeeImages();

        let ec = eventCell.current;
    
        if (ec) {
            ec.addEventListener("mouseover", cellHoverOn);
            ec.addEventListener("mouseout", cellHoverOff);
        }

        return () => {
            if (ec) {
                ec.removeEventListener('mouseover', cellHoverOn)
                ec.removeEventListener('mouseout', cellHoverOff);
            }
        }

    }, [event, account, cellHoverOn, cellHoverOff]);

    const formatDate = (date) => {
        const options = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        };

        return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', ' -');
    }

    const formatSecondsToDate = (seconds) => {
        const milliseconds = seconds * 1000;
        const date = new Date(milliseconds);
        return formatDate(date);
    }

    return (
        <Link to={`${event.id}`} style={{ textDecoration: 'none' }}>
            <div
                className="event-cell"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    background: event.ended ? 'linear-gradient(to bottom, rgba(231, 231, 231, 1.0), rgba(231, 231, 231, 0.8))' : 'linear-gradient(to bottom, rgba(252, 252, 252, 1.0), rgba(252, 252, 252, 0.8))',
                    color: event.ended ? '#999' : 'black',
                    border: event.live ? '3px solid green' : '3px solid #ccc'
                }}
                ref={eventCell}
            >
                <div style={{ fontWeight: '700', marginTop: '4px', display: 'flex', width: '76vw' }}>
                    <div style={{ flex: '1', textAlign: 'left' }}>
                    <div className='event-cell-date' style={{ marginBottom: '12px', fontSize: '16px' }} >{formatSecondsToDate(event.dateTime)}</div>
                    <div className='event-cell-name' style={{ marginBottom: '12px', fontSize: '22px', fontWeight: 800 }} >{event.venueName}</div>
                </div>
                <div style={{ marginLeft: 'auto', marginBottom: '20px' }}>
                    <Button isLoading={reserveLoading === event.id} isDisabled={reserveLoading !== '' && reserveLoading !== event.id} className='reserve-btn' onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!(event.ended && event.live)) reserveAction(event);
                    }}

                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        borderRadius: '20px',
                        padding: '6px 10px',
                        backgroundColor: event.ended ? '#999' : event.live ? 'green' : (account && event.attendees.indexOf(account ? account.uid : '') !== -1 ? '#eb721b' : (account && event.waitlist.indexOf(account ? account.uid : '') !== -1 ? '#eb721b' : (event.attendees.length < event.totalSpots ? 'black': 'black'))),
                        color: 'white',
                        borderStyle: 'none',
                        fontWeight: 'bold',
                        fontSize: '16.2px',
                        height: '40px',
                        width: '92px',
                        textDecoration: 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 200,
                    }}>
                        {event.ended ? 'Ended' : (event.live ? 'Live' : (account && event.attendees.indexOf(account ? account.uid : '') !== -1 ? 'Reserved!' : (account && event.waitlist.indexOf(account ? account.uid : '') !== -1 ? 'Waitlisted!' : (event.attendees.length < event.totalSpots ? 'Reserve': 'Waitlist'))))}
                    </Button>
                    <div className='event-cell-price' style={{ fontSize: '15px', marginTop: '3px' }}>
                        {event.price === 0 ? 'FREE' : '$'+event.price.toFixed(2)}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', width: '76vw' }}>
                <div className='event-cell-address' style={{ paddingLeft: '0px', textAlign: 'left' }}>{event.address}</div>
                <div className='event-cell-host' style={{ fontWeight: '700', marginLeft: 'auto', textAlign: 'right', width: '50vw', alignSelf: 'center', whiteSpace: 'nowrap' }}>Host: {event.hostName}</div>
            </div>
            <div style={{ margin: '20px 0 10px', backgroundColor: '#bbb', width: '77vw', height: '2px' }}></div>
            <div style={{ display: 'flex', width: '76vw', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '17px', margin: '2px 0 8px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                    <div className='event-cell-attendees' style={{ fontWeight: '700', textAlign: 'left', marginBottom: '12px' }}>Attendees</div>
                    <div id='attendeeList' style={{ display: 'flex', height:'40px', width: '38vw', opacity: event.ended ? '50%' : '100%' }}>
                        {attendeeImages.map((image, index) => (
                            <img
                                className='event-cell-image'
                                alt={''}
                                key={index}
                                src={image.src}
                                style={{ zIndex: 10-index, position: 'relative', top: '4px', backgroundColor: 'white', right: `${20 * index}px`, width: image.src !== 'me_active.png' ? '28px' : '34px', height: image.src !== 'me_active.png' ? '28px' : '34px', borderRadius: '50%', border: `${image.src !== 'me_active.png' ? 3 : 0}px solid ${image.src !== 'me_active.png' ? 'black' : 'transparent'}`, marginRight: '4px' }}
                            />
                        ))}
                    </div>
                </div>
                <div style={{ margin: '4px 0', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '50vw'}}>
                    <div className='event-cell-slots' style={{ marginBottom: '4px', fontSize: '18px', fontWeight: '700', textAlign: 'right', width: '33vw' }}>Slots Available</div>
                    <div className='event-cell-slotsNum' style={{ fontSize: '28px', fontWeight: '700' }}>{Math.max(0,event.totalSpots - event.attendees.length)}</div>
                </div>
            </div>
            </div>
        </Link>
    );
};

export default EventCell;
