import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { auth } from './firebase';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react';

const TitleView = ({ account, setProfileModalOpen }) => {

  const profileBtn = useRef(null);

  const profileHoverOn = useCallback(() => {
    profileBtn.current.style.transform = account ? 'scale(1)' : 'scale(1.05)';
  },[account]);

  const profileHoverOff = () => {
    profileBtn.current.style.transform = 'scale(1)';
  };

  useEffect(() => {

    let pb = profileBtn.current;

    if (pb) {
      pb.addEventListener("mouseover", profileHoverOn);
      pb.addEventListener("mouseout", profileHoverOff);
    }

    return () => {
      if (pb) {
        pb.removeEventListener('mouseover', profileHoverOn)
        pb.removeEventListener('mouseout', profileHoverOff);
      }
    }
    
  },[account, profileHoverOn]);

  return (
    <div className="title-bar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: 99 }}>
      <Link style={{ textDecoration: 'none' }} to="/" className="title-bar-text">
        Slotted
      </Link>
      <Menu size={'sm'}>
        <MenuButton ref={profileBtn} className='profile-btn' style={{
            cursor: 'pointer',
            top: '24px',
            right: account ? '128px' : '20px',
            border: '0 none transparent',
            borderRadius: '16px',
            height: '36px',
            width: account ? 'auto' : '104px',
            backgroundColor: account ? '#eb721b' : 'white',
            fontSize: '18px',
            fontWeight: '900',
            letterSpacing: '0',
            padding: '0 10px',
            color: account ? 'white' : 'black'
          }} onClick={() => {
          if (!account) setProfileModalOpen(true);
        }}>
          { account ? account.username.toLowerCase() : 'Sign In' }
        </MenuButton>
        { account && <MenuList style={{
          backgroundColor: 'black',
          fontWeight: 600,
          fontSize: '24px',
          textAlign: 'center',
          opacity: '100',
        }}>
          <MenuItem style={{ 
              backgroundColor: 'transparent',
              fontWeight: 'inherit',
            }}
            onClick={() => console.log('Attending')
          }>
            Attending Events
          </MenuItem>
          { account.isHost && <MenuItem style={{ 
                backgroundColor: 'transparent',
                fontWeight: 'inherit',
              }}
              onClick={() => console.log('Hosting')
            }>
              Hosting Events
            </MenuItem>
          }
          <MenuItem style={{ 
              backgroundColor: 'transparent',
              fontWeight: 'inherit',
            }}
            onClick={() => setProfileModalOpen(true)
          }>
            Profile
          </MenuItem>
          <MenuDivider></MenuDivider>
          <MenuItem style={{ 
            backgroundColor: 'transparent',
            fontWeight: 'inherit',
          }}
          onClick={() => auth.signOut().catch((e) => console.log(e))}
          > Sign Out</MenuItem>
        </MenuList>
      }
    </Menu>
    </div>
  );
};

export default TitleView;