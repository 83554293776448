import './App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { db, functions } from './firebase';
import { httpsCallableFromURL } from 'firebase/functions';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Background from './Background';
import TitleView from './TitleView';
import DefaultView from './DefaultView';
import DetailView from './DetailView';
import ProfileView from './ProfileView';
import PaymentView from './PaymentView';

import OpenMic from './OpenMic';
import SlottedUser from './SlottedUser';

// const stripePromise = loadStripe("pk_live_51NN216JiJ5SaqolZOcy7QUss4OoGRjpe4vwh2hhnId6dQSEl0QT16cOPUW2pMgcu316JtgU2Ia91pZbfHjGutGro00srzU7thB");
const stripePromise = loadStripe("pk_test_51NN216JiJ5SaqolZZSpfh1JgsVWZdWgJ5vzAwiqqPyXLNE5XdTHjrcyWFtX0ueyzBFWmIe6IBcRKtRXFmAyvVd1i00RXcYBy6R");

function App() {

  const [account, setAccount] = useState();
  const [events, setEvents] = useState([]);

  const [savedScrollPosition, setSavedScrollPosition] = useState(0);
  const [scrollBelowEnded, setScrollBelowEnded] = useState(false);
  
  const [query, setQuery] = useState('');

  const [isProfileModalOpen, setProfileModalOpen] = useState(false);

  const [paymentEvent, setPaymentEvent] = useState();
  const [reserveLoading, setReserveLoading] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);

  const [softReset, setSoftReset] = useState(false);

  const [message, setMessage] = useState();

  const appearance = {
    theme: 'night',

    variables: {
      colorPrimary: '#eb721b'
    }
  };

  const options = {
    appearance: appearance,
    mode: 'payment',
    amount: paymentEvent ? paymentEvent.price*100 : 200,
    currency: 'usd',
    paymentMethodCreation: 'manual'
  }

  const parseEvent = (doc) => {
    const dateTime = doc.data().dateTime;
    const venueName = doc.data().venueName;
    const address = doc.data().address;
    const totalSpots = doc.data().totalSpots;
    const _attendees = doc.data().attendees;
    const host = doc.data().host;
    const hostName = doc.data().hostName;
    const requiresPhysicalSignup = doc.data().requiresPhysicalSignup;
    
    if (dateTime && venueName && address && totalSpots && _attendees && host && hostName && requiresPhysicalSignup !== undefined) {

      const reserveTimes = doc.data().reserveTimes ? doc.data().reserveTimes : {};
                        
      const waitlist = doc.data().waitlist ? doc.data().waitlist : [];
      
      const venueType = doc.data().type ? doc.data().type.toUpperCase() : 'MIC';
      
      const timeLimit = doc.data().timeLimit ? doc.data().timeLimit : 0;
      
      const performer = doc.data().performer ? doc.data().performer : '';
      
      const performerStart = doc.data().performerStart ? doc.data().performerStart : 0;
      
      const rules = doc.data().rules ? doc.data().rules : '';
      
      const live = doc.data().live !== undefined ? doc.data().live : false;
      
      const ended = doc.data().ended !== undefined ? doc.data().ended : false;
      
      const price = doc.data().price ? doc.data().price : 0;
      
      const attendees = _attendees.sort((p0, p1) => {
        const reserveTime0 = reserveTimes[p0];
        if (!reserveTime0) return -1;
        
        const reserveTime1 = reserveTimes[p1];
        if (!reserveTime1) return 1;

        return reserveTime1 - reserveTime0;
      });

      return new OpenMic(dateTime, venueName, address, totalSpots, attendees, waitlist, reserveTimes, host, hostName, doc.id, venueType, requiresPhysicalSignup, timeLimit, performer, performerStart, rules, live, ended, price);
    }
  };

  const finishRegister = (event, paymentMethod='') => {
    if (!event || !account) {
      resetPayment();
      setIsLoading(false);
      return;
    }

    const reserveUser = httpsCallableFromURL(functions, 'https://us-central1-open-mic-5cc8e.cloudfunctions.net/reserveUserWeb');

    const requestData = {
      userUID: account.uid,
      venueID: event.id,
      pm: paymentMethod,
      amount: event.price*100,
      currency: 'usd'
    }

    console.log(`Reserving for ${event.venueName}`);

    reserveUser(requestData)
    .then((res) => {
      if (res.data.action) {
        const action = res.data.action;
        const url = action.redirect_to_url.url;
        console.log(url);
        window.location = url;
        return;
      }
      console.log('Registered! Should update from listener');
      resetPayment();
    })
    .catch((error) => {
      console.error(`Error while registering`, error);
      setMessage(`Error: ${error.message}`);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const reserveAction = (event, retry=false) => {
    if (!account) {
      setProfileModalOpen(true);
      return;
    }

    if (event.attendees.indexOf(account.uid) !== -1 || event.waitlist.indexOf(account.uid) !== -1) {
      const cancelPaymentIntent = httpsCallableFromURL(functions, 'https://us-central1-open-mic-5cc8e.cloudfunctions.net/cancelPaymentWeb');

      const requestData = {
        userUID: account.uid,
        venueID: event.id
      }

      console.log(`Refunding for ${event.venueName}`);

      setReserveLoading(event.id);

      cancelPaymentIntent(requestData)
      .then(() => {
        console.log(`Cancelled successfully`);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
        setReserveLoading('');
        setPaymentEvent();
      });

      return;
    }

    setReserveLoading(event.id);

    if (event.price === 0) {
      finishRegister(event);
      return;
    }

    setPaymentEvent(event);

  };

  const _setAccount = useCallback((user) => {
    if (!user) {
      setAccount(null);
      return;
    }
    return onSnapshot(doc(db, `users/${user.uid}`), (snapshot) => {
      const data = snapshot.data();
      setAccount(new SlottedUser(data, user.uid));
    },(error) => {
      console.log(`Account error`, error);
    });
  },[setAccount]);

  useEffect(() => {
    setProfileModalOpen(false);
  },[account]);

  useEffect(() => {
    
    const unsub = onSnapshot(collection(db, 'default'), (snapshot) => {
      const unsortedEvents = snapshot.docs.map(doc => parseEvent(doc));

      const sortedEvents = unsortedEvents.sort((mic0, mic1) => {
        if (mic0.ended !== mic1.ended) {
            return mic1.ended ? -1 : 1;
        } else if (mic0.dateTime === mic1.dateTime) {
            return mic0.venueName < mic1.venueName ? -1 : 1;
        }
        return mic1.dateTime - mic0.dateTime;
      });
      setEvents(sortedEvents);
    });

    return () => {
      unsub();
    }

  },[]);

  const resetPayment = (event=null) => {
    if (event) {
      reserveAction(event, true);
      return;
    }
    setSoftReset(false);
    setReserveLoading('');
    setIsLoading(false);
    setPaymentEvent();
  }

  return (
    <div className="App">
      <Router>
        <Background />
        <TitleView account={account} setProfileModalOpen={setProfileModalOpen} />
        <Routes>
          <Route path="/" element={
            <DefaultView
              account={account}
              events={events}
              query={query}
              setQuery={setQuery}
              savedScrollPosition={savedScrollPosition}
              setSavedScrollPosition={setSavedScrollPosition} 
              scrollBelowEnded={scrollBelowEnded} 
              setScrollBelowEnded={setScrollBelowEnded}
              reserveAction={reserveAction}
              reserveLoading={reserveLoading}
              paymentEvent={paymentEvent}
            />
          }/>
          <Route path=":id" element={
            <DetailView
              account={account}
              events={events}
              reserveAction={reserveAction}
            />
          }/>
        </Routes>
      </Router>
      <ProfileView
        show={isProfileModalOpen}
        onClose={() => setProfileModalOpen(false)}
        account={account}
        _setAccount={_setAccount}
      />
      {paymentEvent &&
        <>
          <Elements options={options} stripe={stripePromise}>
              <PaymentView message={message} setMessage={setMessage} event={paymentEvent} reset={resetPayment} finishRegister={finishRegister} isLoading={isLoading} setIsLoading={setIsLoading} softReset={softReset} setSoftReset={setSoftReset} />
          </Elements>
        </>
      }
    </div>
  );
}

export default App;