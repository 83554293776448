import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

const DetailView = ({ account, events, reserveAction }) => {
  const { id } = useParams();
  const [event, setEvent] = useState();

  const formatDate = (date) => {
    const options = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', ' -');
  }

  const navigate = useNavigate();

  useEffect(() => {

    if (events.find(e => e.id === id)) setEvent(events.find(e => e.id === id));
    else if (events.length > 0 && id) navigate('/');

  },[id, events, navigate, account]);

  return (
    <div className="content" id="content">
      <div style={{ display: event ? 'flex' : 'none', textAlign: '-webkit-center', width: '93vw' }} className="detail-view">
        <h1>{event ? event.venueName : ''}</h1>
        <h2>Hosted by {event ? event.hostName : ''}</h2>
        <h3>{event ? event.address : ''}</h3>
        <h3>{event ? formatDate(new Date(event.dateTime * 1000.0)) : ''}</h3>
        <p style={{ marginTop: event ? event.rules === '' ? '0' : '16px' : '16px', marginBottom: event ? event.rules === '' ? '0' : '16px' : '16px'}}>{event ? event.rules : ''}</p>
        {event ? !event.ended && !event.live ? <div style={{ fontWeight: '700', marginBottom: '28px', textAlign: '-webkit-center' }}>
          <p>{event ? event.totalSpots - event.attendees.length : ''} slot{event.totalSpots - event.attendees.length === 1 ? ' ' : 's'} left!</p>
          <button style={{ height: '48px', cursor: 'pointer' }} onClick={() => {
            reserveAction(event);
          }} className='reserveButton'>
            {event && event.attendees.length < event.totalSpots ? 'Reserve' : 'Waitlist'}
          </button>
          {event ? (event.price === 0 ? 'FREE' : '$'+event.price.toFixed(2)) : ''}
        </div> : '' : ''}
        <Link style={{ textDecoration: 'none' }} to="/">
          <div style={{ marginTop: '32px' }} className='back-button'>
            Back
          </div>
        </Link>
      </div>
    </div>
  );
};

export default DetailView;