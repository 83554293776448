import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAUoCM_8WP-Z7o0w9F-dRZsUYbyFg7YuO0",
    authDomain: "open-mic-5cc8e.firebaseapp.com",
    projectId: "open-mic-5cc8e",
    storageBucket: "open-mic-5cc8e.appspot.com",
    messagingSenderId: "482276707475",
    appId: "1:482276707475:web:2f422d12c97df492d41166",
    measurementId: "G-L698D8LZLZ"
};

const authApp = firebase.initializeApp(firebaseConfig);
const auth = authApp.auth();
const _functions = require('firebase/functions')

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { app, analytics, auth, db, storage, functions };